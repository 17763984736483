import Component from 'vue-class-component';
import { PaymentSystemType } from 'web-platform-core-ui';
import { WpSale } from 'web-platform-vuetify';
import { WpSaleSteps } from 'web-platform-vuetify/src/WpSale/wp-sale';

@Component({
    template: require("./free-tickets-sale.html")
})
export default class FreeTicketsSale extends WpSale {
    CompleteDialog: boolean = false;

    protected async PayOrderAsync(paymentId: number, paymentType: PaymentSystemType): Promise<void> {
        this.LoadingOverlay = true;

        let paymentForm = await this._saleService.GetPaymentFormAsync(this.OrderId);
        if (!paymentForm.Success || paymentForm.Data == null) {
            this._notificationService.Error("", paymentForm.ErrorMessage ? paymentForm.ErrorMessage : this.Terms.InternalError);
            this.LoadingOverlay = false;
            return;
        };

        this.CompleteDialog = true;
        await this._saleService.ClearOrderSaleStateAsync();
        this.Step = WpSaleSteps.Start;
        this.LoadingOverlay = false;
    };
}