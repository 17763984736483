import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Framework } from 'vuetify';
import ru from 'vuetify/src/locale/ru';
import colors from 'vuetify/lib/util/colors';
import { Resize, Scroll } from 'vuetify/lib/directives';
import { extend, wpComponents } from 'web-platform-vuetify';
import FreeTariffItem from './FreeTariffItem/free-tariff-item';
import FreeTariffBundle from './FreeTariffItem/free-tariff-bundle';
import FreePayconfirm from './FreePayconfirm/FreePayconfirm.vue';

const allComponents = extend(wpComponents, vuetifyComponents);
import { vuetifyComponents } from 'web-platform-vuetify/src/vuetify-components';

allComponents['wp-tariff-item'] = FreeTariffItem;
allComponents['wp-tariff-bundle'] = FreeTariffBundle;
//allComponents['wp-payconfirm'] = FreePayconfirm;

Vue.use(Vuetify, { components: allComponents, directives: { Resize, Scroll } });

export default new Vuetify({
	lang: {
		locales: { ru },
		current: 'ru',
	},
	theme: {
		themes: {
			light: {
				secondary: colors.blue.lighten3,
			},
		},
	},
});

declare module 'vue/types/vue' {
	export interface Vue {
		$vuetify: Framework;
	}
}
